import { useRef, useEffect, useState } from "react";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { Icon, Menu, X, ChevronDown } from "react-feather";
import { createPortal } from "react-dom";
import { useBreadcrumbs } from "../../context/BreadcrumbsContext";

import "./TabList.scss";
import { useAuth } from "../../context/AuthContext";
import { useMobileScreenView } from "../../utils/hooks";
import { InternalLink } from "../internal-link/InternalLink";

export interface TabItem {
  to: string;
  label?: string;
  value?: string;
  image?: string;
  icon?: Icon;
  color?: 'green' | 'blue' | 'orange' | 'purple' | 'default',
  alertCount?: number,
  alwaysVisible?: boolean,
  stopCollapse?: boolean
};

interface Props {
  tabs: TabItem[];
  loggedOutTabs?: TabItem[];
  endTabs?: TabItem[];
  selectedTab?: string;
  shrinkTabsOnMobile?: boolean;
  preserveSearchParams?: string[];
}

const getURL = (str: string) => {
  return new URL(
    str.startsWith('http') || str.startsWith('//')
      ? str
      : str.startsWith('/')
        ? `http://test.com${str}`
        : `http://test.com/${str}`
  );
};

const serializeSearchParams = (search: string, disclude: string[] = []) => {
  const searchParams = new URLSearchParams(search);
  const obj = Object.fromEntries(searchParams.entries());
  return Object.keys(obj).filter(key => !disclude.includes(key)).sort().map(key => `${key}=${obj[key]}`).join('&');
};

const getIsSelected = ({ selectedTab, tab, location }: { selectedTab?: string, tab: TabItem, location: any }) => {
  if (selectedTab && selectedTab === tab.value) {
    return true;
  } else {
    const toURL = getURL(tab.to);
    const disclude = ['fullscreen', 'menu'];
    const isSelected = (
      (location.pathname === '/' && toURL.pathname === '/home') ||
      (
        location.pathname === toURL.pathname &&
        serializeSearchParams(location.search, disclude) === serializeSearchParams(toURL.search, disclude)
      )
    );
    return isSelected;
  }
};

export function TabList({
  tabs,
  endTabs,
  selectedTab,
  loggedOutTabs,
  preserveSearchParams = [],
  shrinkTabsOnMobile = false
}: Props) {
  
  const location = useLocation();
  const [ searchParams, setSearchParams ] = useSearchParams();
  const { user } = useAuth();
  const isMobileScreenView = useMobileScreenView();
  const tabListRef = useRef<HTMLDivElement>(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownHeaderRef = useRef<HTMLDivElement>(null);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0, width: 0 });

  const { breadcrumbs } = useBreadcrumbs();

  const forceFullscreen = searchParams.has('fullscreen');
  const isMenuOpen = searchParams.has('menu');
  const showMenuButton = isMobileScreenView || (forceFullscreen && !isMenuOpen) || !user;

  // Use if no page link matches in the tab list
  const title = document.title.split(' | ').slice(0, -1).join(' | ');

  const toggleMenu = () => {
    const newSearchParams = new URLSearchParams(searchParams);
    if (newSearchParams.has('menu')) {
      newSearchParams.delete('menu');
    } else {
      newSearchParams.set('menu', 't');
    }
    setSearchParams(newSearchParams);
  };

  // Show the active tab only on mobile as a page header of sorts
  // Only if the view mode is a page and the user is not logged in
  const useTabs = [ ...(loggedOutTabs && !user ? loggedOutTabs : tabs) ];
  const showBreadcrumbs = breadcrumbs.length > 0;
  if (breadcrumbs.length > 0) {
    useTabs.push(...breadcrumbs.map(crumb => ({
      to: crumb.path,
      label: crumb.label,
      icon: crumb.icon,
      color: crumb.color as 'green' | 'blue' | 'orange' | 'purple' | 'default',
      stopCollapse: !!crumb.stopCollapse
    })));
  }

  const filteredTabs = useTabs.filter((tab, index) => {
    return tab.alwaysVisible || getIsSelected({ selectedTab, tab, location });
  });
  const showTabs = isMobileScreenView && filteredTabs.length
    ? filteredTabs.length
      ? filteredTabs
      : [ { to: location.pathname, label: title } ]
    : useTabs;

  const activeTab = filteredTabs[0] || showTabs[0];
  const ActiveIcon = activeTab?.icon;
  const shouldShowDropdown = isMobileScreenView;
  let breadcrumbIndex = showTabs.findIndex(tab => tab.stopCollapse);
  if (breadcrumbIndex === -1) {
    breadcrumbIndex = showTabs.length - 1;
  } else {
    breadcrumbIndex = Math.max(breadcrumbIndex, 0);
  }
  const BreadcrumbIcon = showTabs[breadcrumbIndex - 1]?.icon;

  const formatTab = (tab: TabItem, index: number, isEnd = false) => {
    const Icon = tab.icon;
    const isActive = getIsSelected({ selectedTab, tab, location });
    return (
      <InternalLink
        key={tab.to}
        className={`tab color-${tab.color || 'default'} ${isActive ? 'active' : ''} ${isEnd ? 'tab-end' : ''} ${index === 0 ? 'tab-first' : ''}`}
        to={tab.to}
        preserveSearchParams={preserveSearchParams}
      >
        {Icon && <Icon />}
        {tab.image && <img src={tab.image} />}
        {tab.label && <div>{tab.label}</div>}
        {((tab?.alertCount || 0) > 0) && (
          <div className="alert-count">
            {tab.alertCount}
          </div>
        )}
      </InternalLink>
    );
  };

  // This is a hack to keep the tab list fixed when the parent element is scrolled
  // It's necessary for iOS overscroll behavior
  useEffect(() => {
    let lastOffset = 0;
    const handleParentScroll = () => {
      const tabList = tabListRef.current;
      const parent = tabList?.parentElement;
      const offset = parent?.scrollTop || 0;
      const delta = offset - lastOffset;
      lastOffset = offset;
      if (tabList && parent) {
        if (
          offset < 0 ||
          (delta < 0 && offset < 100) // Prevent blip when scrolling up
        ) {
          tabList.setAttribute('data-overscroll-fix', 'true');
        } else {
          tabList.removeAttribute('data-overscroll-fix');
        }
      }
    }
    const tabList = tabListRef.current;
    tabList?.parentElement?.addEventListener('scroll', handleParentScroll);
    // Need these to prevent the tab list showing when menu nav is open
    // window.addEventListener('touchstart', handleParentScroll);
    // window.addEventListener('mousedown', handleParentScroll);
    return () => {
      tabList?.parentElement?.removeEventListener('scroll', handleParentScroll);
      // window.removeEventListener('touchstart', handleParentScroll);
      // window.removeEventListener('mousedown', handleParentScroll);
    }
  }, []);

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownOpen && 
          dropdownHeaderRef.current && 
          !dropdownHeaderRef.current.contains(event.target as Node)) {
        setDropdownOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownOpen]);

  useEffect(() => {
    setDropdownOpen(false);
  }, [location.pathname]);

  // Update dropdown position when it's opened
  useEffect(() => {
    if (dropdownOpen && dropdownHeaderRef.current) {
      const rect = dropdownHeaderRef.current.getBoundingClientRect();
      setDropdownPosition({
        top: rect.bottom,
        left: rect.left,
        width: rect.width
      });
    }
  }, [dropdownOpen]);

  return (
    <>
      <div
        data-component="TabList"
        data-is-mobile={isMobileScreenView}
        ref={tabListRef}
      >
        <div className="expander" />
        <div className="container">
          {showMenuButton && (
            <div className="tabs tabs-menu">
              <div className="tab" onClick={toggleMenu}>
                <Menu />
              </div>
            </div>
          )}
          <div className={`tabs main-tabs scrollable ${shrinkTabsOnMobile ? 'shrink-tabs' : ''}`}>
            {shouldShowDropdown && activeTab && useTabs.length > 1 && (
              <>
                <div
                  key={`${activeTab.to}-dropdown`}
                  className={`tab color-${activeTab.color || 'default'} active tab-first dropdown-header`}
                  ref={dropdownHeaderRef}
                  onClick={() => useTabs.length > 1 && setDropdownOpen(!dropdownOpen)}
                >
                  {ActiveIcon && <ActiveIcon />}
                  {activeTab.image && <img src={activeTab.image} />}
                  {activeTab.label && <div>{activeTab.label}</div>}
                  {useTabs.length > 1 && <ChevronDown className="dropdown-icon" />}
                </div>
                {dropdownOpen && createPortal(
                  <div 
                    className="tab-dropdown-content-portal"
                    style={{
                      position: 'fixed',
                      top: `${dropdownPosition.top}px`,
                      left: `${dropdownPosition.left}px`,
                      minWidth: `${dropdownPosition.width}px`,
                      zIndex: 1000
                    }}
                    onMouseDown={(e) => e.stopPropagation()}
                  >
                    {useTabs.map((tab, index) => formatTab(tab, index))}
                  </div>,
                  document.body
                )}
              </>
            )}
            {shouldShowDropdown && activeTab && useTabs.length === 1 && (
              useTabs.map((tab, index) => formatTab(tab, index))
            )}
            {(!shouldShowDropdown || !activeTab) && (
              (showBreadcrumbs && breadcrumbIndex > 1)
                ? (
                    <>
                      <div
                        key={`${activeTab.to}-dropdown`}
                        className={`tab color-${showTabs[breadcrumbIndex - 1]?.color || 'default'} tab-first dropdown-header`}
                        ref={dropdownHeaderRef}
                        onClick={() => showTabs.slice(0, breadcrumbIndex).length > 1 && setDropdownOpen(!dropdownOpen)}
                      >
                        {BreadcrumbIcon && <BreadcrumbIcon />}
                        {showTabs[breadcrumbIndex - 1]?.image && <img src={showTabs[breadcrumbIndex - 1]?.image} />}
                        {showTabs[breadcrumbIndex - 1]?.label && <div>{showTabs[breadcrumbIndex - 1]?.label}</div>}
                        {showTabs.slice(0, breadcrumbIndex).length > 1 && <ChevronDown className="dropdown-icon" />}
                      </div>
                      {dropdownOpen && createPortal(
                        <div 
                          className="tab-dropdown-content-portal"
                          style={{
                            position: 'fixed',
                            top: `${dropdownPosition.top}px`,
                            left: `${dropdownPosition.left}px`,
                            minWidth: `${dropdownPosition.width}px`,
                            zIndex: 1000
                          }}
                          onMouseDown={(e) => e.stopPropagation()}
                        >
                          {showTabs.slice(0, breadcrumbIndex).map((tab, index) => formatTab(tab, index))}
                        </div>,
                        document.body
                      )}
                      {showTabs.slice(breadcrumbIndex).map((tab, index) => formatTab(tab, index))}
                    </>
                  )
                : showTabs.map((tab, index) => formatTab(tab, index))
            )}
            {/* {endTabs && <div className="spacer" />}
            {endTabs && endTabs.map((tab, index) => formatTab(tab, index, true))} */}
          </div>
          {endTabs && (
            <div className={`tabs end-tabs scrollable ${shrinkTabsOnMobile ? 'shrink-tabs' : ''}`}>
              <div className="spacer" />
              {endTabs.map((tab, index) => formatTab(tab, index, true))}
            </div>
          )}
          {!user && (
            <div className="tabs right">
              <InternalLink className="tab login" to="/login">
                Login
              </InternalLink>
              <InternalLink className="tab signup" to="/signup">
                <div className="tab-border" />
                <span>Sign up</span>
              </InternalLink>
            </div>
          )}
        </div>
      </div>
      {/* This is a hack to keep the tab list fixed when the parent element is overscrolled */}
      <div data-component="TabListSpacer" />
    </>
  );
}