import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AlertModal } from '../../components/modals/AlertModal';
import Textbox from '../../components/textbox/Textbox';
import Select from '../../components/select/Select';
import { useAuth } from '../../context/AuthContext';
import { PackageSchema } from '../../utils/types';

import "./CreatePackageModal.scss";
import { EMPTY_PACKAGE } from "../dashboard/agents-chat/code/AgentsCode";
import { ContentButton } from '../../components/button/ContentButton';
import { GitBranch, Loader, Package, Plus, X } from 'react-feather';
import { InfoBox } from '../../components/info/InfoBox';
import API from '../../utils/api';
import { toast } from '../../services/toast';
import PackageCard from './PackageCard';

interface PackageName {
  name: string;
  organization: string;
  shortName: string;
  version: string;
  type: string;
}

interface CreatePackageModalProps {
  from?: PackageName;
  to?: PackageName;
}

export function CreatePackageModal({ from, to }: CreatePackageModalProps) {

  const initialToEnvironment = (
    to && from && to?.name === from?.name && to?.version !== 'production'
      ? from?.version === 'development'
        ? 'staging'
        : 'production'
      : to?.version || 'development'
  );

  const [name, setName] = useState(to?.shortName || '');
  const [environment, setEnvironment] = useState(initialToEnvironment);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { organization } = useAuth();
  const navigate = useNavigate();

  const isLoadingRef = useRef('');
  const [ isPackageLoading, setIsPackageLoading ] = useState(!!from);
  const [ pkg, setPkg ] = useState<PackageSchema | null>(null);
  const [ pkgError, setPkgError ] = useState('');

  useEffect(() => {
    if (from && isLoadingRef.current !== `${from.name}/${from.version}`) {
      isLoadingRef.current = `${from.name}/${from.version}`;
      setIsPackageLoading(true);
      (async () => {
        const params: {[key: string]: string} = {
          name: from.name,
        };
        if (from.version.startsWith('v-')) {
          params.environment = 'production';
          params.version = from.version;
        } else {
          params.environment = from.version;
        }
        try {
          const pkg = await API.get(`v1/packages/details`, params);
          setPkg(pkg as PackageSchema);
        } catch (err) {
          const error = err as Error;
          setPkgError(`Could not find package ${from.name}: ${error.message}`);
        }
        setIsPackageLoading(false);
        isLoadingRef.current = '';
      })();
    } else {
      setPkg(null);
    }
  }, [from?.name, from?.version]);

  const organizationName = to?.organization || organization.name;

  let canChooseName = true;
  let canSelectEnvironment = true;
  let environmentOptions = [
    { value: 'development', label: 'development' },
    { value: 'staging', label: 'staging' },
    { value: 'production', label: 'production' },
  ];
  if (
    to && from && to.name === from.name
  ) {
    canChooseName = false;
    let env = from.version.startsWith('v-') ? 'production' : from.version;
    environmentOptions = environmentOptions.filter((option) => option.value !== env);
  } else {
    environmentOptions.pop();
  }

  const createPackage = useCallback(async (to_name: string, toEnvironment: string) => {
    const to_environment = toEnvironment.startsWith('v-') ? 'production' : toEnvironment;
    const to_version = toEnvironment.startsWith('v-') ? toEnvironment : null;
    const saveFiles = EMPTY_PACKAGE;
    const base64Files: {[key: string]: string} = {};
    for (const pathname in saveFiles) {
      base64Files[pathname] = saveFiles[pathname].value._base64;
    }
    const params = {
      name: to_name,
      environment: to_environment,
      version: to_version,
      base64Files,
      publishError: false,
      autoInstall: true,
      _stream: {
        log: ({ event, data }: { event: string, data: any }) => {
          // Push deploy logs to console
          console.log(data);
        }
      }
    } as {[key: string]: any};
    const result = await API.post(
      'v1/packages',
      params
    );
    return result as PackageSchema;
  }, []);

  const forkPackage = useCallback(async (from_name: string, fromEnvironment: string, to_name: string, toEnvironment: string) => {
    const from_environment = fromEnvironment.startsWith('v-') ? 'production' : fromEnvironment;
    const from_version = fromEnvironment.startsWith('v-') ? fromEnvironment : null;
    const to_environment = toEnvironment.startsWith('v-') ? 'production' : toEnvironment;
    const to_version = toEnvironment.startsWith('v-') ? toEnvironment : null;
    const params = {
      name: from_name,
      environment: from_environment,
      version: from_version,
      to_name,
      to_environment,
      to_version,
      _stream: {
        log: ({ event, data }: { event: string, data: any }) => {
          // Push deploy logs to console
          console.log(data);
        }
      }
    };
    const result = await API.post(
      'v1/packages/fork',
      params
    );
    return result as PackageSchema;
  }, []);

  const handleCreate = useCallback(async () => {
    if (!name.trim()) {
      setError('Package name is required');
      return;
    }
    setIsLoading(true);
    try {
      const fullName = `@${organizationName}/${name.trim()}`;
      let pkg: PackageSchema;
      if (from) {
        pkg = await forkPackage(from.name, from.version, fullName, environment);
        const pkgVersion = pkg.packageVersions[0];
        navigate(`/packages/${pkg.display_name}/${pkgVersion.version || pkgVersion.environment}`);
      } else {
        pkg = await createPackage(fullName, environment);
        const pkgVersion = pkg.packageVersions[0];
        navigate(`/code/${pkg.display_name}/${pkgVersion.version || pkgVersion.environment}?fullscreen=true`);
      }
    } catch (err: any) {
      setError(err.message || 'Failed to create package');
    }
    setIsLoading(false);
  }, [name, environment, organizationName, createPackage, navigate]);

  return (
    <div data-component="CreatePackageModal">
      <div className="create-package-modal">
        <div className="create-package-modal-header">
          {!from && !to && <Plus />}
          {from && (to?.name !== from.name) && <GitBranch />}
          {from && (to?.name === from.name) && <Package />}
          <span>
          {!from && !to && 'Create package'}
          {from && (to?.name !== from.name) && `Fork package from ${from.name}`}
          {from && (to?.name === from.name) && `Publish package`}
          </span>
        </div>
        <div className="create-package-modal-body">
          {(pkg || isPackageLoading) && (
            <div className="package-card-container">
              {isPackageLoading && (
                <div className="package-card-loading">
                  <Loader className="loading-spin" />
                </div>
              )}
              {!isPackageLoading && pkg && (
                <PackageCard
                  pkg={pkg}
                  environment={from?.version.startsWith('v-') ? 'production' : from?.version || 'development'}
                  version={from?.version.startsWith('v-') ? from?.version : null}
                  mode="mini"
                />
              )}
            </div>
          )}
          <div className="package-row">
            {canChooseName && (
              <div className="grouping grow">
                <div className="package-title">@{organizationName}/</div>
                <Textbox
                  autoFocus={canChooseName}
                  placeholder="my-package"
                  value={name}
                  disabled={!canChooseName}
                  onChange={(value) => {
                    setName(value);
                    setError('');
                  }}
                />
              </div>
            )}
            <div className={`grouping ${!canChooseName ? 'grow' : ''}`}>
              {!canChooseName && (
                <div className="package-publish-to">Publish package to</div>
              )}
              <Select
                value={environment}
                options={environmentOptions}
                readonly={!canSelectEnvironment}
                onChange={(value) => {
                  setEnvironment(value);
                  setError('');
                }}
              />
              {canChooseName && (
                <Select
                  value="public"
                  options={[{ value: 'public', label: 'Public' }]}
                  readonly
                />
              )}
            </div>
          </div>
          {(error || pkgError) && (
            <div className="package-error">
              <span>{error || pkgError}</span>
              <ContentButton
                icon={X}
                onClick={() => {
                  setError('');
                  setPkgError('');
                }}
              />
            </div>
          )}
          <div className="package-actions">
            <ContentButton
              color="blue"
              icon={!from && !to ? Plus : from && (to?.name !== from.name) ? GitBranch : Package}
              loading={isLoading}
              onClick={() => handleCreate()}
            >
              {!from && !to && 'Create'}
              {from && (to?.name !== from.name) && `Fork`}
              {from && (to?.name === from.name) && `Publish`}
            </ContentButton>
          </div>
        </div>
      </div>
    </div>
  );
} 