import { LoaderFunctionArgs } from "react-router-dom";
import API, { ErrorWithDetails } from "../../utils/api";
import { PackageSchema } from "../../utils/types";

export interface PackagesPageLoaderData {
  organizationName: string;
  view: 'development' | 'new' | null;
  packages: PackageSchema[];
};

export default function PackagesPageLoader() {
  return async ({ params, request } : LoaderFunctionArgs) => {

    const { org } = params;
    let organizationName = org;
    let view = null;
    if (organizationName?.startsWith('@')) {
      organizationName = organizationName.slice(1);
    } else if (organizationName === 'development') {
      organizationName = void 0;
      view = 'development';
    } else if (organizationName === 'new') {
      organizationName = void 0;
      view = 'new';
    } else if (organizationName) {
      throw new Error('Invalid organization name');
    }

    const packageParams: { [key: string]: any } = {};
    if (organizationName) {
      packageParams.organization = organizationName;
      packageParams.show_agent = true;
      packageParams.show_private = true;
      packageParams.show_environments = ['development', 'staging', 'production'];
    } else if (view === 'development') {
      packageParams.show_private = true;
      packageParams.show_environments = ['development', 'staging'];
    } else {
      packageParams.show_private = true;
      packageParams.show_environments = ['production'];
    }

    if (view !== 'new') {
      const packagesResponse = await API.get(
        'v1/packages',
        packageParams
      );
      const packages = (packagesResponse?.data || []) as PackageSchema[];

      return {
        organizationName,
        view,
        packages
      };

    } else {
      return {
        organizationName,
        view,
        packages: []
      }
    }
  
  }
};