import { useState, useEffect, useRef, useMemo } from "react";
import { AgentSchema, PackageEndpointSchema, PackageSchema } from "../../../../utils/types";
import { Plus, X, Search, Check, Save, Package, Delete, Info, Coffee } from 'react-feather';
import Button from "../../../../components/button/Button";
import Textbox from "../../../../components/textbox/Textbox";
import API, { ErrorWithDetails } from "../../../../utils/api";
import { sleep } from "../../../../utils/sleep";
import { formatRecent } from "../../../../utils/time";

import "./AgentsPackages.scss";
import { InfoBox } from "../../../../components/info/InfoBox";
import { InternalLink } from "../../../../components/internal-link/InternalLink";
import PackageCard from "../../../packages/PackageCard";
import { toast } from "../../../../services/toast";
import { ContentButtonGroup } from "../../../../components/button/ContentButton";
import { ContentButton } from "../../../../components/button/ContentButton";
import { useSearchParams } from "react-router-dom";

const serialize = (obj: any) => {
  obj = obj || {};
  if (obj && typeof obj === "object") {
    return Object.keys(obj).sort().map(key => {
      return [key, obj[key]].join('=');
    }).join('&');
  } else {
    return obj;
  }
};

interface Props {
  agent: AgentSchema;
  onAgentUpdated: (agent: AgentSchema) => void;
}

export function AgentsPackages({ agent, onAgentUpdated }: Props) {

  const [ searchParams ] = useSearchParams();
  const [viewAgent, setViewAgent] = useState<AgentSchema>(agent);
  const [botPackages, setBotPackages] = useState<{[key: string]: any}>(agent.apiKeychain.keychainConfigs?.[0]?.available_packages || {});

  const [isLoading, setIsLoading] = useState(true); // used for initial load
  const [isSearching, setIsSearching] = useState(false);
  const [packageSearch, setPackageSearch] = useState("");
  const [packages, setPackages] = useState<PackageSchema[]>([]);
  const [installedPackages, setInstalledPackages] = useState<PackageSchema[]>([]);
  const [developmentPackages, setDevelopmentPackages] = useState<PackageSchema[]>([]);
  const packagesRef = useRef(0);

  // Must do this...
  const installedPackagesList = useMemo(() => {
    return Object.keys(botPackages).map(key => `${key}/${botPackages[key].version}`);
  }, [botPackages]);

  const pageView = {
    'installed': 'installed',
    'discover': 'discover',
    'development': 'development'
  }[searchParams.get('view') || 'installed'] || 'installed';

  const fetchPackages = async ({ delay = 300 }: { delay?: number }) => {
    setIsSearching(true);
    const id = ++packagesRef.current;
    await sleep(delay);
    if (id !== packagesRef.current) {
      return;
    }
    const [
      installedPackagesResponse,
      allPackagesResponse,
      developmentPackagesResponse
     ] = await Promise.all([
      API.get(
        'v1/packages',
        {
          show_private: true,
          show_environments: ['development', 'staging', 'production'],
          include: installedPackagesList,
        }
      ),
      API.get(
        'v1/packages',
        {
          show_private: true,
          show_environments: ['production'],
          query: packageSearch,
          limit: { count: 3, offset: 0 }
        }
      ),
      API.get(
        'v1/packages',
        {
          show_private: true,
          show_environments: ['development', 'staging'],
          query: packageSearch,
          limit: { count: 3, offset: 0 }
        }
      )
    ]);
    if (id !== packagesRef.current) {
      return;
    }
    const installedPackages = (installedPackagesResponse?.data || []) as Array<PackageSchema>;
    const packages = (allPackagesResponse?.data || []) as Array<PackageSchema>;
    const developmentPackages = (developmentPackagesResponse?.data || []) as Array<PackageSchema>;
    setIsSearching(false);
    setIsLoading(false);
    setInstalledPackages(installedPackages);
    setDevelopmentPackages(developmentPackages);
    setPackages(packages);
  };

  useEffect(() => {
    fetchPackages({ delay: 0 });
  }, [installedPackagesList]);

  useEffect(() => {
    setViewAgent(agent);
    setBotPackages(agent.apiKeychain.keychainConfigs?.[0]?.available_packages || {});
  }, [agent]);

  const updateAgentPackages = async (pkgs: {[key: string]: string}) => {
    const addPackages = Object.keys(pkgs).filter(key => !botPackages[key]); // new packages
    const removePackages = Object.keys(botPackages).filter(key => !pkgs[key]); // removed packages
    const modifiedPackages = Object.keys(pkgs)
      .filter(key => {
        return (
          !addPackages.includes(key) &&
          !removePackages.includes(key) &&
          JSON.stringify(botPackages[key]) !== JSON.stringify(pkgs[key])
        );
      }); // modified packages
    setBotPackages(pkgs);
    try {
      const agentResult = await API.put(
        "v1/agents",
        {
          id: viewAgent.unique_id,
          packages: pkgs
        }
      );
      setViewAgent(agentResult as AgentSchema);
      onAgentUpdated(agentResult as AgentSchema);
      for (const pkg of addPackages) {
        toast.message({ type: 'success', message: `Package installed\n${pkg}`, duration: 3000 });
      }
      for (const pkg of removePackages) {
        toast.message({ type: 'warning', icon: Delete, message: `Package removed\n${pkg}`, duration: 3000 });
      }
      for (const pkg of modifiedPackages) {
        toast.message({ type: 'success', message: `Package updated\n${pkg}`, duration: 3000 });
      }
    } catch (e) {
      const error = e as ErrorWithDetails;
      toast.message({ type: 'error', message: `Failed to install packages: ${error.message}`, duration: 5000 });
    }
  };

  const viewPackages = pageView === 'installed'
    ? installedPackages
    : pageView === 'development'
      ? developmentPackages
      : packages;

  return (
    <div data-component="AgentsPackages">
      <div className="packages-content">
        <div className="scrollable">
          {false && (
            <div className="form-row">
              <Textbox
                heading="Search for packages..."
                endIcon={Search}
                value={packageSearch}
                loading={isSearching}
                onChange={search => {
                  setPackageSearch(search);
                  fetchPackages({});
                }}
              />
            </div>
          )}
          <div className="packages-primer">
            <InfoBox>
              Packages extend the capabilities of your agent and are published by the community.<br />
              You can <InternalLink to={`/packages/new`}>create your own</InternalLink> or
              write
              {" "}<InternalLink to={`/chat/${viewAgent.unique_id}?tab=code&fullscreen=t`}>custom code</InternalLink>{" "}
              for this agent, specifically.
            </InfoBox>
          </div>
          <div className="packages-actions">
            <div className="packages-actions-row">
              <ContentButtonGroup>
                <ContentButton
                  icon={Check}
                  active={pageView === 'installed'}
                  color="grey"
                  to={`/chat/${viewAgent.unique_id}?tab=packages`}
                >
                  Installed {installedPackages.length ? `(${installedPackages.length})` : ''}
                </ContentButton>
                <ContentButton
                  icon={Search}
                  active={pageView === 'discover'}
                  color="grey"
                  to={`/chat/${viewAgent.unique_id}?tab=packages&view=discover`}
                >
                  Discover
                </ContentButton>
                <ContentButton
                  icon={Coffee}
                  active={pageView === 'development'}
                  color="grey"
                  to={`/chat/${viewAgent.unique_id}?tab=packages&view=development`}
                >
                  Development
                </ContentButton>
              </ContentButtonGroup>
            </div>
          </div>
          {pageView === 'installed' && (
            <>
              {installedPackages.length === 0 && !isLoading && (
                <div className="packages-results-empty">
                  You have not installed any packages yet.<br /><br />
                  <ContentButton
                    icon={Search}
                    color="blue"
                    to={`/chat/${viewAgent.unique_id}?tab=packages&view=discover`}
                  >
                    Discover packages
                  </ContentButton>
                </div>
              )}
            </>
          )}
          {pageView === 'discover' && (
            <>
              {viewPackages.length === 0 && !isLoading && (
                <div className="packages-results-empty">
                  No packages found.
                </div>
              )}
            </>
          )}
          {viewPackages.length > 0 && (
            <div className="packages-results">
              {viewPackages.map(pkg => {
                const installVersion = botPackages[pkg.display_name]?.version || '';
                let environment: string | undefined = void 0;
                let version = null;
                if (installVersion.startsWith('v-')) {
                  environment = 'production';
                  version = installVersion;
                } else if (installVersion) {
                  environment = installVersion;
                }
                return (
                  <PackageCard
                    key={`${pageView}:${pkg.display_name}`}
                    pkg={pkg}
                    mode="install"
                    environment={environment}
                    version={version}
                    installedPackages={botPackages}
                    onInstall={(pkg, version, keys, permissions) => {
                      if (version) {
                        updateAgentPackages({
                          ...botPackages,
                          [pkg.display_name]: {
                            version: version.version || version.environment || '',
                            permissions,
                            keys
                          }
                        });
                      }
                    }}
                    onUninstall={(pkg) => {
                      const pkgs = {...botPackages};
                      delete pkgs[pkg.display_name];
                      updateAgentPackages(pkgs);
                    }}
                  />
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}