import { useNavigate } from "react-router-dom";
import { PackageSchema, PackageVersionSchema } from "../../utils/types";
import { formatRecent } from "../../utils/time";
import { InternalLink } from "../../components/internal-link/InternalLink";
import "./PackageCard.scss"
import { Activity, Check, Code, Edit, Lock, Package, Star, Cpu, Plus, Delete, Trash, Settings, Eye } from "react-feather";
import { ContentButton } from "../../components/button/ContentButton";
import { useAuth } from "../../context/AuthContext";
import Select from "../../components/select/Select";
import { useState } from "react";
import API from "../../utils/api";
import { AlertModal } from "../../components/modals/AlertModal";
import Textbox from "../../components/textbox/Textbox";
import { toast } from "../../services/toast";
import ImageUploader from "../../components/uploader/ImageUploader";
import { useMobileDevice } from "../../utils/hooks";
import Toggle from "../../components/toggle/Toggle";

export default function PackageCard({
  pkg,
  environment,
  version = null,
  mode = 'card',
  installedPackages = {},
  onEdit = () => {},
  onInstall = () => {},
  onUninstall = () => {}
} : {
  pkg: PackageSchema,
  environment?: string,
  version?: string | null,
  mode?: 'card' | 'page' | 'mini' |'install',
  installedPackages?: { [key: string]: any },
  onEdit?: (pkg: PackageSchema) => void,
  onInstall?: (pkg: PackageSchema, version?: PackageVersionSchema, keys?: string[], permissions?: { [key: string]: string[] }) => void,
  onUninstall?: (pkg: PackageSchema, version?: PackageVersionSchema) => void
}) {

  const [ viewPkg, setViewPkg ] = useState(pkg);
  const [ title, setTitle ] = useState(viewPkg.display_title);
  const [ description, setDescription ] = useState(viewPkg.description);
  const [ isEditingTitle, setIsEditingTitle ] = useState(false);
  const [ isEditingDescription, setIsEditingDescription ] = useState(false);
  const [ isUploadingImage, setIsUploadingImage ] = useState(false);

  const { user, organization } = useAuth();
  const navigate = useNavigate();
  const isMobileDevice = useMobileDevice();

  const canEdit = organization?.name === viewPkg.organization.name && !viewPkg.agent && mode === 'page';
  const canEditCode = organization?.name === viewPkg.organization?.name && mode !== 'install';

  const packageVersion = (
    pkg?.packageVersions.find(v => v.environment === environment && v.version === version) ||
    pkg?.packageVersions.find(v => v.environment === 'production' && v.is_latest) ||
    pkg?.packageVersions.find(v => v.environment === 'staging' && v.is_latest) ||
    pkg?.packageVersions.find(v => v.environment === 'development' && v.is_latest)
  );
  const packageDeployment = packageVersion?.packageDeployments[0];
  const definitionsJSON: { [key: string]: any } = packageDeployment?.definitions_json || {};
  const definitions = Object.keys(definitionsJSON).map(key => {
    const def = definitionsJSON[key];
    const name = def.name;
    const method = def.name.split('#')[1] || 'POST';
    const route = def.name.split('#')[0];
    const routeName = `${method} ${route}`;
    const title = def.description?.split(/\n|\.\s/gi)[0] || routeName;
    const description = name === routeName ? '' : routeName;
    return { name, title, description };
  });

  // Installation settings, default is all are on
  const installed = !!installedPackages[pkg.display_name];
  const [ viewInstallSettings, setViewInstallSettings ] = useState(false);
  const [ installKeys, setInstallKeys ] = useState<string[]>(
    installedPackages[pkg.display_name]?.keys || packageVersion?.packageDeployments[0]?.required_keys?.map(k => k.name) || []
  );
  const missingKeys = packageVersion?.packageDeployments[0]?.required_keys?.filter(k => !installKeys.includes(k.name)) || [];
  const [ installPermissions, setInstallPermissions] = useState<{ [key: string]: string[] }>(
    installedPackages[pkg.display_name]?.permissions || definitions.reduce((acc, curr) => {
      acc[curr.name] = ['*'];
      return acc;
    }, {} as { [key: string]: string[] })
  );

  version = packageVersion?.version || packageVersion?.environment || null;
  const [ selectedVersion, setSelectedVersion ] = useState(version);

  const versions = pkg?.packageVersions.map(v => v.version || v.environment);
  const versionsOptions = versions.map(v => ({
    label: v,
    value: v
  }));

  const shortTitle = viewPkg.agent
    ? viewPkg.agent.agentConfigs?.[0]?.name.split('/').slice(-1)[0]
    : title;
  const avatarCharacters = shortTitle.split(/\W/).slice(0, 2).map(c => c[0]).join('').toUpperCase();

  const colors = ['purple', 'blue', 'green', 'orange', 'red'];
  const packageColor = colors[new Date(viewPkg.created_at).getTime() % colors.length]

  const packageType = viewPkg.display_name.startsWith('@')
    ? 'public'
    : viewPkg.display_name.split('/')[0];
  const packageTypeLabel = packageType[0].toUpperCase() + packageType.slice(1);

  const codeUrl = viewPkg.agent
    ? `/chat/${viewPkg.agent.unique_id}?tab=code&fullscreen=t`
    : `/code/${viewPkg.display_name}/${version || environment || 'development'}?&fullscreen=t`;

  const updatePackage = async (thumbnailImage: { _base64: string } | false | null = null) => {
    setIsEditingTitle(false);
    setIsEditingDescription(false);
    if (thumbnailImage) {
      setIsUploadingImage(true);
    }
    try {
      const result = await API.put(`v1/packages/`, {
        name: viewPkg.display_name,
        title: title,
        description: description,
        thumbnail_image: thumbnailImage
      });
      const updatedPkg = result as PackageSchema;
      const newViewPkg = JSON.parse(JSON.stringify(pkg));
      newViewPkg.display_title = updatedPkg.display_title;
      newViewPkg.description = updatedPkg.description;
      newViewPkg.thumbnail_image_url = updatedPkg.thumbnail_image_url;
      setTitle(newViewPkg.display_title);
      setDescription(newViewPkg.description);
      setViewPkg(newViewPkg);
      onEdit?.(newViewPkg);
      toast.message({ type: 'success', message: `Package information updated!`, duration: 1000 });
    } catch (e) {
      const error = e as Error;
      toast.message({ type: 'error', message: error.message, duration: 5000 });
    }
    setIsUploadingImage(false);
  }

  return (
    <div data-component="PackageCard"
      data-mode={mode}
      data-is-mobile-device={isMobileDevice}
      data-environment={packageVersion?.environment}
    >
      {mode === 'mini' && (
        <>
          <div className="package-card-header">
            <div className="package-card-rating" style={{display: 'none'}}>
              <Star className="star"/>
              <span>4.5</span>
            </div>
            <div className="vertical-divider" style={{display: 'none'}} />
            <div className="package-card-activity" style={{display: 'none'}}>
              <Activity />
              <span>100</span>
            </div>
            <div className="vertical-divider" style={{display: 'none'}} />
            <div className="package-card-type">
              <span>
                <div className="package-card-icon">
                  {viewPkg.agent && <Cpu />}
                  {viewPkg.is_private && <Lock />}
                  {!(viewPkg.agent || viewPkg.is_private) && <Package />}
                </div>
                <div className="package-card-type-name">
                  {packageTypeLabel}
                </div>
              </span>
            </div>
          </div>
          <div className="package-avatar">
            <div className="package-avatar-content" data-color={viewPkg.thumbnail_image_url ? '' : packageColor}>
              {!viewPkg.thumbnail_image_url && (
                <div className="package-avatar-placeholder">
                  {avatarCharacters}
                </div>
              )}
              {viewPkg.thumbnail_image_url && (
                <img
                  src={viewPkg.thumbnail_image_url}
                  alt={viewPkg.display_title}
                />
              )}
            </div>
            <div className="package-card-title">
              <div className="package-card-display-name">
                {!isEditingTitle && (
                  <>
                    {shortTitle}
                  </>
                )}
                {isEditingTitle && (
                  <div className="package-card-input">
                    <Textbox
                      size="small"
                      value={title}
                      autoFocus={true}
                      onChange={(value) => setTitle(value)}
                      onSubmit={() => updatePackage()}
                    />
                    <ContentButton
                      color="grey"
                      icon={Check}
                      onClick={() => updatePackage()}
                    />
                  </div>
                )}
              </div>
              <div className="package-card-description">
                {pkg.display_name} &middot; {version || environment}
              </div>
            </div>
          </div>
        </>
      )}
      {mode !== 'mini' && (
        <>
          <div className="package-card-header">
            <div className="package-card-rating" style={{display: 'none'}}>
              <Star className="star"/>
              <span>4.5</span>
            </div>
            <div className="vertical-divider" style={{display: 'none'}} />
            <div className="package-card-activity" style={{display: 'none'}}>
              <Activity />
              <span>100</span>
            </div>
            <div className="vertical-divider" style={{display: 'none'}} />
            <div className="package-card-type">
              <span>
                <div className="package-card-icon">
                  {viewPkg.agent && <Cpu />}
                  {viewPkg.is_private && <Lock />}
                  {!(viewPkg.agent || viewPkg.is_private) && <Package />}
                </div>
                <div className="package-card-type-name">
                  {packageTypeLabel}
                </div>
              </span>
            </div>
            {mode === 'install' && installed && (
              <div className="package-card-install-status">
                <Check />
                <span>
                  Installed
                </span>
              </div>
            )}
          </div>
          <div className="package-avatar">
            <div className="package-avatar-content" data-color={viewPkg.thumbnail_image_url ? '' : packageColor}>
              {!viewPkg.thumbnail_image_url && (
                <div className="package-avatar-placeholder">
                  {avatarCharacters}
                </div>
              )}
              {!canEdit &&viewPkg.thumbnail_image_url && (
                <img
                  src={viewPkg.thumbnail_image_url}
                  alt={viewPkg.display_title}
                />
              )}
              {canEdit && (
                <ImageUploader
                  type="inline"
                  loading={isUploadingImage}
                  image={viewPkg.thumbnail_image_url || void 0}
                onChange={(file) => {
                  const thumbnailImage = file ? {_base64: file} : false;
                  updatePackage(thumbnailImage);
                  }}
                />
              )}
            </div>
            <div className="package-card-title">
              <div className="package-card-display-name">
                {!isEditingTitle && (
                  <>
                    <InternalLink to={`/packages/${viewPkg.display_name}${packageVersion?.environment && packageVersion.environment !== 'production' ? `/${packageVersion?.environment}` : ''}`}>
                      {shortTitle}
                    </InternalLink>
                    {canEdit && (
                      <ContentButton
                        color="grey"
                        size="small"
                        icon={Edit}
                        onClick={() => {
                          setIsEditingTitle(true);
                        }}
                      />
                    )}
                  </>
                )}
                {isEditingTitle && (
                  <div className="package-card-input">
                    <Textbox
                      size="small"
                      value={title}
                      autoFocus={true}
                      onChange={(value) => setTitle(value)}
                      onSubmit={() => updatePackage()}
                    />
                    <ContentButton
                      color="grey"
                      icon={Check}
                      onClick={() => updatePackage()}
                    />
                  </div>
                )}
              </div>
              <div className="package-card-description">
                {viewPkg.agent && (
                  <span>
                    Custom code package for agent
                  </span>
                )}
                {!viewPkg.agent && (
                  <>
                    {!isEditingDescription && (
                      <>
                        <span>{description}</span>
                        {canEdit && (
                          <ContentButton
                            color="grey"
                            size="small"
                            icon={Edit}
                            onClick={() => setIsEditingDescription(true)}
                          />
                        )}
                      </>
                    )}
                    {isEditingDescription && (
                      <div className="package-card-input">
                        <Textbox
                          size="small"
                          value={description}
                          autoFocus={true}
                          onChange={(value) => setDescription(value)}
                          onSubmit={() => updatePackage()}
                        />
                        <ContentButton
                          color="grey"
                          icon={Check}
                          onClick={() => updatePackage()}
                        />
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="package-card-body">
            <div className="package-card-footer">
              <div className="package-card-info">
                <div className="package-card-necessary">
                  <div className="package-card-name">
                    {viewPkg.display_name}
                  </div>
                  <span>&middot;</span>
                  <div className="package-card-name" data-version={selectedVersion}>
                    {selectedVersion}
                  </div>
                  <span>&middot;</span>
                  <div className="package-card-name">
                    {formatRecent(packageVersion?.updated_at || viewPkg.created_at)}
                    &nbsp;by&nbsp;
                    <InternalLink to={`/packages/@${viewPkg.organization.name}`}>
                      @{viewPkg.organization.name}
                    </InternalLink>
                  </div>
                </div>
              </div>
              <div className="spacer" />
              <div className="package-card-actions">
                {versionsOptions.length > 1 && !installed && (
                  <Select
                    readonly={false}
                    size="small"
                    options={versionsOptions}
                    value={selectedVersion}
                    onChange={(value) => {
                      if (mode !== 'install') {
                        navigate(`/packages/${viewPkg.display_name}/${value}`);
                        setSelectedVersion(value);
                      } else {
                        setSelectedVersion(value);
                      }
                    }}
                  />
                )}
                {mode === 'install' && (
                  <ContentButton
                    darken={true}
                    alertCount={installed ? missingKeys.length : 0}
                    icon={Eye}
                    iconStyling={viewInstallSettings ? 'off' : 'default'}
                    onClick={() => {
                      setViewInstallSettings(viewInstallSettings => !viewInstallSettings);
                    }}
                  />
                )}
                {mode === 'install' && !installed && (
                  <ContentButton
                    color="green"
                    icon={Plus}
                    onClick={() => {
                      const packageVersion = viewPkg.packageVersions.find(v => v.environment === selectedVersion || v.version === selectedVersion);
                      onInstall?.(viewPkg, packageVersion, installKeys, installPermissions);
                    }}
                  >
                    Install
                  </ContentButton>
                )}
                {mode === 'install' && installed && (
                  <ContentButton
                    darken={true}
                    icon={Trash}
                    onClick={() => {
                      const packageVersion = viewPkg.packageVersions.find(v => v.environment === selectedVersion || v.version === selectedVersion);
                      onUninstall?.(viewPkg, packageVersion);
                    }}
                  >
                    Uninstall
                  </ContentButton>
                )}
                {canEditCode && (
                  <ContentButton
                    darken={mode === 'page' ? false : true}
                    color={mode === 'page' ? 'grey' : 'default'}
                    icon={Code}
                    to={codeUrl}
                  >
                    Edit code
                  </ContentButton>
                )}
              </div>
            </div>
          </div>
        </>
      )}
      {mode === 'install' && (
        <div className="package-install-settings" style={{maxHeight: viewInstallSettings ? '1500px' : 0}}>
          {((packageDeployment?.required_keys?.length || 0) > 0) && (
            <div className="package-install-keys">
              <div className="package-install-keys-header">
                <span>Required keys</span>
                <span className="label">&middot;</span>
                <span className="label">
                  Shared from your Keychain
                </span>
              </div>
              <div className="package-install-keys-body">
                {packageDeployment?.required_keys?.map(key => (
                  <div className="key-permission" key={key.name}>
                    <span>
                      <span className="key-permission-name">
                        {key.name}
                        {missingKeys.find(k => k.name === key.name) && (
                          <span className="required-key-missing">
                            required
                          </span>
                        )}
                      </span>
                      <span className="key-permission-description">
                        {key.description}
                      </span>
                    </span>
                    <span className="spacer" />
                    <Toggle
                      size="small"
                      value={installKeys.includes(key.name)}
                      onClick={(value) => {
                        const setKeys = new Set(installKeys);
                        if (setKeys.has(key.name)) {
                          setKeys.delete(key.name);
                        } else {
                          setKeys.add(key.name);
                        }
                        const newInstallKeys = Array.from(setKeys);
                        setInstallKeys(newInstallKeys);
                        installed &&onInstall?.(viewPkg, packageVersion, newInstallKeys, installPermissions);
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
          <div className="package-install-keys">
            <div className="package-install-keys-header">
              <span>Endpoints</span>
              <span className="label">&middot;</span>
              <span className="label">
                Tools enabled for this package
              </span>
            </div>
            <div className="package-install-keys-body">
              {definitions.map(key => (
                <div className="key-permission" key={key.name}>
                  <span>
                    <span className="key-permission-name">
                      {key.title}
                    </span>
                    <span className="key-permission-description">
                      {key.description}
                    </span>
                  </span>
                  <span className="spacer" />
                  <Toggle
                    size="small"
                    value={!!installPermissions[key.name]}
                    onClick={(value) => {
                      if (installPermissions[key.name]) {
                        delete installPermissions[key.name];
                      } else {
                        // TODO: Allow specific users to be added
                        installPermissions[key.name] = ['*'];
                      }
                      const newInstallPermissions = { ...installPermissions };
                      setInstallPermissions(newInstallPermissions);
                      installed && onInstall?.(viewPkg, packageVersion, installKeys, newInstallPermissions);
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}