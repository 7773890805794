import { useState, useEffect, useRef } from "react";
import { useSearchParams, Link, useLocation } from "react-router-dom";

import { AgentSchema, StandaloneAgentSchema, FunctionResult, SQLResult, ApiKeychainSchema } from "../../../utils/types";

import { ArrowLeft, Code, Database, Key, MessageCircle, Package, Settings, User, X } from "react-feather";

import { useAuth } from "../../../context/AuthContext";
import { useMobileScreenView, useTabletScreenView } from "../../../utils/hooks";
import API, { ErrorWithDetails } from "../../../utils/api";
import { ChatMessageEntry } from "../../../components/chat/ChatMessage";
import { AlertModal } from "../../../components/modals/AlertModal";

import "./AgentsChat.scss";
import { ChatBox } from "../../../components/chat/ChatBox";
import { AgentsSettings } from "./settings/AgentsSettings";
import { AgentsPackages } from "./packages/AgentsPackages";
import { AgentsCode } from "./code/AgentsCode";
import { TabList } from "../../../components/nav/TabList";
import { ContentButton } from "../../../components/button/ContentButton";
import { Helmet } from "react-helmet-async";
import { AgentsKeychain } from "./keychain/AgentsKeychain";

interface Props {
  agent: AgentSchema;
  onAgentUpdated?: (agent: AgentSchema) => void;
  onAgentArchived?: (id: string) => void;
}

export function getKeychainMissingKeys(keychain?: ApiKeychainSchema) {
  const pkgs = keychain?.keychainConfigs?.[0]?.available_packages || {};
  const keys = Object.keys(keychain?.keychainConfigs?.[0]?.keys || {});
  const requiredKeys: string[] = [];
  for (const name in pkgs) {
    const pkg = pkgs[name];
    const keys = (pkg?.keys || []) as any[];
    for (const key of keys) {
      if (!requiredKeys.includes(key) && key !== '*') {
        requiredKeys.push(key);
      }
    }
  }
  const missingKeys = requiredKeys.filter(key => !keys.includes(key));
  return { missingKeys };
}

export default function AgentsChat({ agent, onAgentUpdated, onAgentArchived }: Props) {

  const location = useLocation();
  const [ searchParams, setSearchParams ] = useSearchParams();
  const [ isTyping, setIsTyping ] = useState(false);
  const typingDebounceRef = useRef<number>(0);
  const isFirstRender = useRef(true); // Debounce react dev mode ...
  const isMobileScreenView = useMobileScreenView();
  const isTabletScreenView = useTabletScreenView();
  const isProfileOpen = searchParams.has('profile');

  const searchTab = searchParams.get('tab');

  const viewTab = {
    'chat': 'chat',
    'packages': 'packages',
    'keychain': 'keychain',
    'code': 'code'
  }[searchTab || ''] || 'chat';

  const { user, organization } = useAuth();

  const [viewAgent, setViewAgent] = useState<AgentSchema>(agent);
  const [messages, setMessages] = useState<ChatMessageEntry[]>([]);
  const [alertMessage, setAlertMessage] = useState('');

  const [ notificationElement, setNotificationElement ] = useState<React.ReactNode | undefined>(void 0);
  const notificationsAcknowledgedRef = useRef<{[key: string]: boolean}>({});

  const keychain = viewAgent.apiKeychain;
  const { missingKeys } = getKeychainMissingKeys(keychain);
  const keychainAlertCount = missingKeys.length;

  useEffect(() => {
    setViewAgent(agent);
  }, [agent]);

  useEffect(() => {
    if (searchParams.has('welcome')) {
      const newParams = new URLSearchParams(searchParams);
      newParams.delete('welcome');
      setSearchParams(newParams, { replace: true });
      // Use ref to prevent double send in dev mode
      if (isFirstRender.current) {
        isFirstRender.current = false;
        setTimeout(() => {
          sendChatMessage('Hey! Nice to meet you.');
        }, 0);
      }
    }
  }, []);

  useEffect(() => {
    const messages: ChatMessageEntry[] = [];
    for (const conversation of agent.conversations) {
      const userMessage: ChatMessageEntry = {
        time: new Date(conversation.query_sent_at || conversation.created_at),
        app: false,
        message: conversation.query
      };
      messages.push(userMessage);
      const responseMessage: ChatMessageEntry = {
        time: new Date(conversation.created_at),
        app: true,
        message: conversation.response,
        functions: conversation.functions,
        sql: conversation.sql,
        notification: conversation.rate_limit?.message,
        created_at: conversation.created_at,
        is_text_warned: conversation.is_text_warned,
        is_text_filtered: conversation.is_text_filtered,
        is_text_blocked: conversation.is_text_blocked
      };
      messages.push(responseMessage);
    }
    setMessages(messages);
  }, [agent.unique_id]);

  useEffect(() => {
    const lastMessage = messages.slice().reverse().find((m: ChatMessageEntry) => m.app);
    const notificationsAcknowledged = notificationsAcknowledgedRef.current;
    if (
      lastMessage?.notification &&
      !notificationsAcknowledged[`${agent.unique_id}:${lastMessage.created_at}`]
    ) {
      setNotificationElement(lastMessage.notification);
    } else if (!lastMessage?.notification) {
      setNotificationElement(void 0);
    }
  }, [messages]);

  const dismissNotification = () => {
    const lastMessage = messages.slice().reverse().find((m: ChatMessageEntry) => m.app);
    const notificationsAcknowledged = notificationsAcknowledgedRef.current;
    if (lastMessage) {
      notificationsAcknowledged[`${agent.unique_id}:${lastMessage.created_at}`] = true;
    }
    setNotificationElement(void 0);
  };

  const sendChatMessage = (value: string) => {
    const message: ChatMessageEntry = {
      time: new Date(),
      app: false,
      message: value
    };
    setMessages(messages => messages.concat(message));
    if (viewAgent) {
      (async () => {
        const n = typingDebounceRef.current;
        const typingTimeout = setTimeout(() => {
          if (n === typingDebounceRef.current) {
            setIsTyping(true);
          }
        }, 750);
        try {
          const queryResult = await API.post(
            "v1/agents/requests",
            {
              id: viewAgent.unique_id,
              query: value,
            }
          );
          typingDebounceRef.current++;
          clearTimeout(typingTimeout);
          setIsTyping(false);
          const responseAgent = queryResult?.agent as StandaloneAgentSchema;
          const shouldClear = !!queryResult?.clear;
          const response = queryResult?.response || "";
          const functs = (queryResult?.functions || []) as FunctionResult[];
          const sql = queryResult?.sql as SQLResult;
          const notification = queryResult?.rate_limit?.message || "";
          const created_at = queryResult?.created_at;
          const is_text_warned = queryResult?.is_text_warned || false;
          const is_text_filtered = queryResult?.is_text_filtered || false;
          const is_text_blocked = queryResult?.is_text_blocked || false;
          if (response) {
            const responseMessage: ChatMessageEntry = {
              time: new Date(),
              app: true,
              message: response,
              functions: functs,
              sql,
              notification,
              created_at,
              is_text_warned,
              is_text_filtered,
              is_text_blocked
            };
            // Add the new conversation to the agent and trigger a re-render of the agent card
            if (shouldClear) {
              setMessages(messages => [ responseMessage ]);
              agent.conversations = [
                {
                  query: value,
                  query_sent_at: new Date().toISOString(),
                  response,
                  functions: functs,
                  sql,
                  rate_limit: notification,
                  billed: queryResult?.billed || null,
                  created_at,
                  is_text_warned,
                  is_text_filtered,
                  is_text_blocked
                }
              ];
              // Send empty conversations up the chain
              const updatedAgent = JSON.parse(JSON.stringify(agent));
              updatedAgent.conversations = [];
              updatedAgent.last_modified_at = responseAgent.last_modified_at;
              onAgentUpdated?.(updatedAgent);
            } else {
              setMessages(messages => messages.concat(responseMessage));
              agent.conversations.push({
                query: value,
                query_sent_at: new Date().toISOString(),
                response,
                functions: functs,
                sql,
                rate_limit: notification,
                billed: queryResult?.billed || null,
                created_at,
                is_text_warned,
                is_text_filtered,
                is_text_blocked
              });
              agent.conversations.splice(0, agent.conversations.length - 1);
              // We need to force an update to the agent card
              const updatedAgent = JSON.parse(JSON.stringify(agent));
              updatedAgent.last_modified_at = responseAgent.last_modified_at;
              onAgentUpdated?.(updatedAgent);
            }
          }
        } catch (e) {
          clearTimeout(typingTimeout);
          setIsTyping(false);
          const error = e as Error;
          const responseMessage: ChatMessageEntry = {
            time: new Date(),
            app: true,
            message: error.message,
            functions: []
          };
          setMessages(messages => messages.concat(responseMessage));
        }
      })();
    }
  }

  const handleAgentUpdate = (updatedAgent: AgentSchema) => {
    setViewAgent(updatedAgent);
    onAgentUpdated?.(updatedAgent);
  };

  const handleAgentArchived = (id: string) => {
    onAgentArchived?.(id);
  };

  const toggleOpenProfile = () => {
    if (searchParams.has('profile')) {
      searchParams.delete('profile');
      setSearchParams(searchParams);
    } else {
      searchParams.set('profile', 't');
      setSearchParams(searchParams);
    }
  };

  let title = viewAgent?.agentConfigs?.[0]?.name || 'Chat';
  if (title.length > 20) {
    title = title.slice(0, 19) + '...';
  }
  const page = viewTab[0].toUpperCase() + viewTab.slice(1);

  // Force favicon on location update
  // Executes after the router
  useEffect(() => {
    const favicon = document.querySelector('link[rel="shortcut icon"]') as HTMLLinkElement;
    if (favicon) {
      favicon.href = viewAgent?.agentConfigs?.[0]?.profile_image_url || '/logo/funct-logo-avatar-circle.svg';
    }
  }, [location.pathname, viewAgent]);
  
  return (
    <div
      data-component="AgentsChat"
      data-is-profile-open={isProfileOpen}
    >
      <Helmet>
        <title>{title} | {page !== 'Chat' ? `${page} | ` : ''}Funct</title>
      </Helmet>
      {alertMessage && (
        <AlertModal
          message={alertMessage}
          onConfirm={() => setAlertMessage('')}
        />
      )}
      <div className="main-content">
        {!isMobileScreenView && (
          <TabList
            preserveSearchParams={['search']}
            tabs={[
              { label: viewAgent?.agentConfigs?.[0]?.name || 'Bot', value: 'chat', to: `/chat/${viewAgent?.unique_id}`, image: viewAgent?.agentConfigs?.[0]?.profile_image_url || '/logo/funct-logo-avatar-circle.svg', alwaysVisible: true },
              { label: 'Packages', value: 'packages', to: `/chat/${viewAgent?.unique_id}?tab=packages`, icon: Package, color: 'green' },
              { alertCount: keychainAlertCount, label: 'Keychain', value: 'keychain', to: `/chat/${viewAgent?.unique_id}?tab=keychain`, icon: Key, color: 'orange' },
              { label: 'Code', value: 'code', to: `/chat/${viewAgent?.unique_id}?tab=code&fullscreen=t`, icon: Code, color: 'blue' },
            ]}
            endTabs={(
              isProfileOpen
                ? []
                : [
                    { label: 'Settings', value: 'profile',to: `/chat/${viewAgent?.unique_id}?profile=t`, icon: Settings, color: 'default' },
                  ]
            )}
            selectedTab={viewTab}
          />
        )}
        {isMobileScreenView && (
          <TabList
            preserveSearchParams={['search']}
            shrinkTabsOnMobile={true}
            tabs={[
              { label: viewAgent?.agentConfigs?.[0]?.name || 'Bot', value: 'chat', to: `/chat/${viewAgent?.unique_id}`, image: viewAgent?.agentConfigs?.[0]?.profile_image_url || '/logo/funct-logo-avatar-circle.svg', alwaysVisible: true }
            ]}
            endTabs={[
              { value: 'packages', to: `/chat/${viewAgent?.unique_id}?tab=packages`, icon: Package, color: 'green' },
              { alertCount: keychainAlertCount, value: 'keychain', to: `/chat/${viewAgent?.unique_id}?tab=keychain`, icon: Key, color: 'orange' },
              { value: 'code', to: `/chat/${viewAgent?.unique_id}?tab=code`, icon: Code, color: 'blue' },
              { value: 'profile',to: `/chat/${viewAgent?.unique_id}?profile=t`, icon: Settings, color: 'default' },
            ]}
            selectedTab={viewTab}
          />
        )}
        <div className={`central-content ${viewTab === 'chat' ? 'chat-content' : ''}`}>
          {viewTab === 'chat' && (
            <ChatBox
              activeId={viewAgent?.unique_id}
              botName={viewAgent?.agentConfigs?.[0]?.name || void 0}
              botAvatar={viewAgent?.agentConfigs?.[0]?.profile_image_url || '/logo/funct-logo-avatar-circle.svg'}
              userName={organization.formatted_name}
              messages={messages}
              onSubmit={value => sendChatMessage(value)}
              showTypingIndicator={isTyping}
              onEditProfileClick={toggleOpenProfile}
              notificationElement={notificationElement}
              onDismissNotification={dismissNotification}
            />
          )}
          {viewTab === 'packages' && (
            <AgentsPackages
              agent={viewAgent}
              onAgentUpdated={handleAgentUpdate}
            />
          )}
          {viewTab === 'keychain' && (
            <AgentsKeychain
              agent={viewAgent}
              onAgentUpdated={handleAgentUpdate}
            />
          )}
          {viewTab === 'code' && (
            <AgentsCode
              agent={viewAgent}
              onAgentUpdated={handleAgentUpdate}
            />
          )}
        </div>
      </div>
      {viewTab === 'chat' && (
        <div className="right-sidebar" data-is-tablet={isTabletScreenView}>
          <div className="right-sidebar-container">
            <AgentsSettings 
              agent={viewAgent}
              onAgentUpdated={handleAgentUpdate}
              onAgentArchived={handleAgentArchived}
            />
          </div>
          <div className="close-right-sidebar">
            <ContentButton
              icon={isTabletScreenView ? ArrowLeft : X}
              darken={true}
              onClick={toggleOpenProfile}
            >
              {isTabletScreenView ? 'Back to chat' : 'Close'}
            </ContentButton>
          </div>
        </div>
      )}
    </div>
  );

}