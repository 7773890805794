import { useState, useEffect } from "react";
import { useLoaderData, useRevalidator, useNavigate, useLocation, useSearchParams } from "react-router-dom";

import { PackageSchema } from "../../utils/types";
import PackageCard from "./PackageCard";
import { PackagesPageLoaderData } from "./PackagePageLoader";

import API, { ErrorWithDetails } from "../../utils/api";

import "./PackagesPage.scss";
import { Info, Package, Coffee, Plus, Search, User, GitBranch } from "react-feather";
import { useBreadcrumbs } from "../../context/BreadcrumbsContext";
import { Helmet } from "react-helmet-async";

import { ContentButton, ContentButtonGroup } from "../../components/button/ContentButton";
import { InternalLink } from "../../components/internal-link/InternalLink";
import { useAuth } from "../../context/AuthContext";
import { CreatePackageModal } from './CreatePackageModal';
import { InfoBox } from "../../components/info/InfoBox";

const parsePackageName = (name: string, type: 'from' | 'to') => {
  if (!name) {
    return void 0;
  }
  const names = name.split('/');
  if (name.startsWith('@')) {
    return {
      name: names.slice(0, 2).join('/'),
      shortName: names[1],
      version: names[2] || (type === 'from' ? 'production' : 'development'),
      organization: (names[0] || '').slice(1),
      type: 'public'
    };
  } else {
    return {
      name: names.slice(0, 3).join('/'),
      shortName: names[2],
      version: names[3] || (type === 'from' ? 'production' : 'development'),
      organization: (names[1] || '').slice(1),
      type: names[0]
    };
  }
};

export default function PackagesPage() {

  const { user, organization } = useAuth()
  const { organizationName, view, packages } = useLoaderData() as PackagesPageLoaderData;
  const { breadcrumbs, updateBreadcrumbs } = useBreadcrumbs();
  const [ searchParams, setSearchParams ] = useSearchParams();

  const from = parsePackageName(searchParams.get('from') || '', 'from');
  const to = parsePackageName(searchParams.get('to') || '', 'to');

  let displayTitle = 'Packages';
  let description = 'Explore packages created by the community';
  if (organizationName) {
    displayTitle = `${organizationName} | Packages`;
    description = `Explore packages created by ${organizationName}`;
  } else if (view === 'development') {
    displayTitle = 'Development packages';
    description = 'Packages in development and staging environments.';
  } else if (view === 'new') {
    displayTitle = 'Create package';
    description = 'Create a new package to extend the capabilities of your agent';
    if (from?.name) {
      displayTitle = `Fork package`;
      if (to?.name) {
        if (from.name === to.name) {
          displayTitle = `Publish package`;
          description = `Publish a new version of ${from.name}`;
        }
      }
    }
  }

  useEffect(() => {
    if (organizationName) {
      const breadcrumbs = [
        {
          icon: User,
          label: `@${organizationName}`,
          path: `/packages/@${organizationName}`
        }
      ];
      if (!user) {
        breadcrumbs.unshift({
          icon: Package,
          label: 'Packages',
          path: '/packages'
        });
      }
      updateBreadcrumbs(breadcrumbs);
    } else if (view === 'development') {
      const breadcrumbs = [
        {
          icon: Coffee,
          label: 'Development',
          path: '/packages/development'
        }
      ];
      if (!user) {
        breadcrumbs.unshift({
          icon: Package,
          label: 'Packages',
          path: '/packages'
        });
      }
      updateBreadcrumbs(breadcrumbs);
    } else if (view === 'new') {
      const breadcrumbs = [
        {
          icon: (!from && !to) ? Plus : from && (to?.name !== from.name) ? GitBranch : Package,
          label: displayTitle,
          path: '/packages/new' +
            (from || to ? '?' : '') +
            (from ? `from=${encodeURIComponent(searchParams.get('from') || '')}` : '') +
            (to ? `&to=${encodeURIComponent(searchParams.get('to') || '')}` : '')
        }
      ];  
      updateBreadcrumbs(breadcrumbs);
    } else {
      updateBreadcrumbs([]);
    }
    return () => {
      updateBreadcrumbs([]);
    }
  }, [organizationName, view]);

  return (
    <div data-component="PackagesPage">
      <Helmet>
        <title>{displayTitle} | Funct</title>
        <meta name="description" content={description} />
      </Helmet>
      {view === 'new' && (
        <CreatePackageModal
          from={from}
          to={to}
        />
      )}
      {view !== 'new' && (
        <>
          <div className="packages-title">
            <div className="heading">
              {!organizationName && !view && 'Package registry'}
              {view === 'development' && 'Development packages'}
              {!!organizationName && `Packages by @${organizationName}`}
            </div>
            <div className="subheading">
              {!organizationName && !view && 'Packages extend the capabilities of your agent.'}
              {view === 'development' && 'Packages in development and staging environments.'}
              {!!organizationName && `All packages you have access to published by this user.`}
            </div>
          </div>
          <div className="packages-actions">
            <div className="packages-actions-row">
              <ContentButtonGroup>
                <ContentButton
                  icon={Search}
                  active={!organizationName && !view}
                  color="grey"
                  to="/packages"
                >
                  Discover
                </ContentButton>
                <ContentButton
                  icon={Coffee}
                  active={view === 'development'}
                  color="grey"
                  to="/packages/development"
                >
                  Development
                </ContentButton>
                {user && (
                  <ContentButton
                    icon={User}
                    active={organizationName === organization?.name}
                    color="grey"
                    to={`/packages/@${organization.name}`}
                  >
                    @{organization.name}
                  </ContentButton>
                )}
              </ContentButtonGroup>
              <div className="spacer" />
              {user && (
                <ContentButton
                  color="blue"
                  icon={Plus}
                  to="/packages/new"
                >
                  Create package
                </ContentButton>
              )}
            </div>
          </div>
          <div className="packages-list">
            {!packages.length && (
              <div className="packages-list-empty">
                No packages found.
              </div>
            )}
            {packages.map(pkg => {
              return (
                <PackageCard
                  key={`${view}//${pkg.display_name}`}
                  pkg={pkg}
                />
              );
            })}
          </div>
        </>
      )}
    </div>
  );

};